.applications-page-title-header {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  background-color: #ffffff; }
  .applications-page-title-header .applications-page-title-header__section {
    padding: 7px 0px 7px 2px; }

#REPORTS {
  margin: 0 auto 0 238px;
  padding: 0 25px;
  overflow: hidden;
  background-color: #fff; }
