.full-screen-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1C21DF; }
  .full-screen-menu__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1306px;
    padding: 25px;
    margin: 0 auto; }
  .full-screen-menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .full-screen-menu__list {
    text-align: center;
    margin: auto 0;
    opacity: 1.0; }
    .full-screen-menu__list:hover__list-item {
      opacity: 0.2; }
  .full-screen-menu__list-item {
    margin-bottom: 24px; }
    .full-screen-menu__list-item:last-child {
      margin-bottom: 0; }
  .full-screen-menu__link {
    display: inline-block;
    margin: 0 40px;
    font-family: 'Inter-Medium';
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-transform: uppercase; }
    @media (max-width: 768.98px) {
      .full-screen-menu__link {
        font-size: 48px;
        line-height: 1.13; } }
