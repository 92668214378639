#side-drawer-menu {
  height: calc(100vh);
  width: 300px;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: #FBFBF9;
  overflow-x: hidden;
  padding-top: 30px;
  border-right: 1px solid #FBFBF9;
  overflow-y: auto; }
  @media (max-width: 768.98px) {
    #side-drawer-menu {
      width: 100%; } }
  @media (max-width: 1023.98px) {
    #side-drawer-menu {
      height: calc(100%);
      border-right: 0px solid #FBFBF9; } }
  #side-drawer-menu.is-mobile-applicant-logged-in-sidebar-menu .side-drawer-menu-list {
    margin-top: 50px; }
  #side-drawer-menu.is-mobile-applicant-logged-in-sidebar-menu .side-drawer-menu-logout-btn {
    margin-bottom: 100px; }
  #side-drawer-menu .program-sponsors div[class$="-SelectContainer"] div[class$="-Control"] {
    padding: 0px 10px !important; }
  #side-drawer-menu .program-sponsors div[class$="-SelectContainer"] div[class$="-menu"] {
    max-width: 243px !important; }
  #side-drawer-menu .program-sponsors .field label {
    margin-left: 20px;
    margin-right: 20px; }
  #side-drawer-menu .program-sponsors .css-1vt0lg1 {
    margin-top: 0px;
    padding: 0px 10px; }
  #side-drawer-menu .program-sponsors .css-26l3qy-menu {
    max-width: 243px; }
  #side-drawer-menu .side-drawer-logo-notification {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    margin: 0px 30px; }
    #side-drawer-menu .side-drawer-logo-notification .side-drawer-logo .small-icon {
      display: none; }
    #side-drawer-menu .side-drawer-logo-notification .side-drawer-notification-icon svg {
      fill: red;
      height: 18px; }
  #side-drawer-menu .rewards-box {
    background: #1C21DF;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 5px;
    padding: 22px; }
    #side-drawer-menu .rewards-box .rewards-box__top {
      display: flex;
      justify-content: space-between; }
      #side-drawer-menu .rewards-box .rewards-box__top .rewards-box__top__left .rewards-box__top-title {
        margin-bottom: 8px; }
        #side-drawer-menu .rewards-box .rewards-box__top .rewards-box__top__left .rewards-box__top-title .EyebrowWhite {
          font-weight: 400; }
      #side-drawer-menu .rewards-box .rewards-box__top .rewards-box__top__left .rewards-box__top-scholarships {
        margin-bottom: 16px; }
        #side-drawer-menu .rewards-box .rewards-box__top .rewards-box__top__left .rewards-box__top-scholarships .H6DesktopWhite {
          font-weight: 300; }
    #side-drawer-menu .rewards-box .rewards-box__middle {
      margin-bottom: 20px; }
      #side-drawer-menu .rewards-box .rewards-box__middle .progress-complete-cell-type .progress-complete-cell-type__text {
        display: none; }
      #side-drawer-menu .rewards-box .rewards-box__middle .progress-complete-cell-type .progress-complete-cell-type__bar {
        background: rgba(255, 255, 255, 0.4); }
        #side-drawer-menu .rewards-box .rewards-box__middle .progress-complete-cell-type .progress-complete-cell-type__bar.active {
          background: #ffffff; }
    #side-drawer-menu .rewards-box .rewards-box__bottom .LabelWhite {
      font-weight: 300; }
  #side-drawer-menu.side-drawer-menu-small {
    width: 100px; }
    #side-drawer-menu.side-drawer-menu-small .program-sponsors .field label {
      margin-left: 5px; }
    #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification {
      margin: 0px 10px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-logo .large-icon {
        display: none; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-logo .small-icon {
        display: unset; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-notification-icon {
        margin-bottom: 15px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-hamburger-menu-icon .hamburger-menu-icon-container {
        padding: 4px 7px;
        background: #1a282c; }
        #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-hamburger-menu-icon .hamburger-menu-icon-container:hover {
          background: #f6f6f6; }
          #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-hamburger-menu-icon .hamburger-menu-icon-container:hover .hamburger-menu-icon-container__icon {
            background: #1a282c; }
        #side-drawer-menu.side-drawer-menu-small .side-drawer-logo-notification .side-drawer-hamburger-menu-icon .hamburger-menu-icon-container__icon {
          width: 18px;
          margin: 6px 0;
          background: #ffffff; }
    #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list {
      margin-top: 50px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list .field .H6DesktopBlack > div {
        padding: 0 5px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list .field div[class$="-SelectContainer"] div[class$="-Control"] {
        padding: 0 10px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list .field div[class$="-SelectContainer"] div[class$="-menu"] div[class$="-MenuList"] div[class$="-option"] {
        padding: 8px 8px;
        font-size: 12px; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list .side-drawer-menu-list-item {
        margin-left: 0px;
        justify-content: center; }
        #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list .side-drawer-menu-list-item .side-drawer-menu-list__item-copy {
          display: none; }
      #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list.side-drawer-menu-logout-btn .side-drawer-menu-list-item {
        margin-left: 0px;
        justify-content: center; }
        #side-drawer-menu.side-drawer-menu-small .side-drawer-menu-list.side-drawer-menu-logout-btn .side-drawer-menu-list-item .side-drawer-menu-list__item-copy {
          display: unset;
          margin-left: 0px;
          text-align: center; }
    #side-drawer-menu.side-drawer-menu-small .rewards-box {
      display: none; }

body.is-modal-visible #side-drawer-menu {
  z-index: 15; }

.side-drawer-menu-list {
  margin-top: 30px; }
  .side-drawer-menu-list.side-drawer-menu-logout-btn {
    margin-top: 34px;
    padding-bottom: 34px; }

.side-drawer-menu-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer; }
  .side-drawer-menu-list-item:hover {
    background: #35888D; }
    .side-drawer-menu-list-item:hover svg {
      filter: brightness(0) invert(1); }
    .side-drawer-menu-list-item:hover .side-drawer-menu-list__item-copy .CTAGrey {
      color: #ffffff; }
  .side-drawer-menu-list-item.CTAGreen {
    background: #1E5558; }
    .side-drawer-menu-list-item.CTAGreen .side-drawer-menu-list__item-copy .CTAGreen {
      color: #ffffff;
      font-style: italic; }
  .side-drawer-menu-list-item:last-child {
    margin-bottom: 105px; }
  .side-drawer-menu-list-item svg {
    min-width: 32px; }
  .side-drawer-menu-list-item .side-drawer-menu-list__item-copy {
    margin-left: 10px; }

#gpa-icon {
  width: 30px; }

/*  SECTIONS  */
.section {
  clear: both;
  padding: 0px;
  margin: 0px; }

/*  COLUMN SETUP  */
.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1%; }

.col:first-child {
  margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after {
  content: "";
  display: table; }

.group:after {
  clear: both; }

.group {
  zoom: 1;
  /* For IE 6/7 */ }

/*  GRID OF TWELVE  */
.span_12_of_12 {
  width: 100%; }

.phone .react-tel-input {
  margin-top: 8px; }
  .phone .react-tel-input .field__tel-field {
    margin-left: 0;
    padding: 0 50px; }

.span_11_of_12 {
  width: 91.58%; }

.span_10_of_12 {
  width: 83.16%; }

.span_9_of_12 {
  width: 74.75%; }

.span_8_of_12 {
  width: 66.33%; }

.span_7_of_12 {
  width: 57.91%; }

.span_6_of_12 {
  width: 49.5%; }

.span_5_of_12 {
  width: 41.08%; }

.span_4_of_12 {
  width: 32.66%; }

.span_3_of_12 {
  width: 24.25%; }

.span_2_of_12 {
  width: 15.83%; }

.span_1_of_12 {
  width: 7.416%; }

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media (max-width: 768.98px) {
  .col {
    margin: 1% 0 1% 0%; }
  .span_1_of_12,
  .span_2_of_12,
  .span_3_of_12,
  .span_4_of_12,
  .span_5_of_12,
  .span_6_of_12,
  .span_7_of_12,
  .span_8_of_12,
  .span_9_of_12,
  .span_10_of_12,
  .span_11_of_12,
  .span_12_of_12 {
    width: 100%; } }

.hamburger-menu-icon-container {
  background: #f6f6f6;
  padding: 3.5px 9px;
  border-radius: 4px;
  cursor: pointer; }
  .hamburger-menu-icon-container:active {
    background: #1a282c; }
    .hamburger-menu-icon-container:active > div {
      background-color: #ffffff; }
  .hamburger-menu-icon-container:hover {
    background: #1a282c; }
    .hamburger-menu-icon-container:hover > div {
      background-color: #ffffff; }
  .hamburger-menu-icon-container__icon {
    width: 22px;
    height: 3px;
    background-color: #1a282c;
    margin: 9px 0; }

.save-changes-confirmation-alert p {
  flex-direction: row-reverse; }

@media (min-width: 769px) {
  .side-drawer-logo-notification .side-drawer-hamburger-menu-icon {
    display: none; } }
