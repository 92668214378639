.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: #ffffff !important;
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 24;
  max-height: 75vh;
  max-width: 42vw;
  width: 100%; }
  @media (max-width: 479.98px) {
    .modal {
      max-width: 80%; } }
  @media (min-width: 769px) {
    .modal {
      top: 55%; } }
  @media (max-width: 768.98px) {
    .modal {
      top: 59%; } }

.modal__background {
  position: fixed;
  z-index: 23;
  height: 100vh;
  width: 100vw;
  opacity: 0.3;
  background-color: #1a282c; }

.modal__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; }
