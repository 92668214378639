.notification-list__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f6f6f6;
  padding: 20px 0;
  cursor: pointer; }
  .notification-list__item:last-child {
    border-bottom: 1px solid #f6f6f6; }

.notification-list__item-indicator {
  min-width: 12px;
  margin-right: 8px; }
  .notification-list__item-indicator .notify-dot {
    height: 10px;
    width: 10px; }

.notification-list__item-icon {
  margin-right: 14px;
  min-width: 32px;
  min-height: 32px; }

.notification-list__item-left {
  display: flex;
  align-items: center; }

.notification-list__item-copy {
  max-width: 420px; }
  .notification-list__item-copy .title-and-mark-as-complete-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .notification-list__item-copy .title-and-mark-as-complete-container .BodyDefaultBoldBlack {
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .notification-list__item-copy .title-and-mark-as-complete-container .task-notification-mark-as-complete-display {
      display: flex;
      align-items: center;
      font-family: 'Inter-Medium'; }
      .notification-list__item-copy .title-and-mark-as-complete-container .task-notification-mark-as-complete-display input[type="checkbox"] {
        margin-right: 10px; }

.task-list-column-1 {
  width: 50%; }

.task-list-column-2 {
  width: 20%; }

.task-list-column-3 {
  width: 20%; }

.task-list-column-4 {
  width: 10%; }

.checkbox-complete {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center; }

.taskcomplete {
  display: inline-flex;
  cursor: pointer;
  position: relative; }

.taskcomplete > span {
  color: #979797; }

.taskcomplete > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #1C21DF;
  border-radius: 100px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #1C21DF;
  cursor: pointer; }

.taskcomplete > input:checked {
  border: 1px solid #1C21DF;
  background-color: #1C21DF; }

.taskcomplete > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 0.33rem;
  top: 0.3rem;
  font-size: 12px; }

.taskcomplete > input:active {
  border: 1px solid #1C21DF;
  background-color: #1C21DF; }

.checkbox-incomplete {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center; }

.taskincomplete {
  display: inline-flex;
  cursor: pointer;
  position: relative; }

.taskincomplete > span {
  color: #34495e; }

.taskincomplete > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #1E5558;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #ffffff;
  cursor: pointer; }

.edit-my-task-icon {
  cursor: pointer; }
