.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack, .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button h4, .msg-overlay-list-bubble .msg-overlay-list-bubble-search .msg-overlay-list-bubble-search__input-container .msg-overlay-list-bubble-search__search-typeahead-input, .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names,
.msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names, .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble-item__time-stamp,
.msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble-item__time-stamp, .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2,
.msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2 {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.msg-overlay-list-bubble {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  flex: 0 0 350px;
  width: calc(100% - 20px);
  min-width: 0;
  background: #ffffff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1); }
  .msg-overlay-list-bubble .msg-overlay-bubble-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px 0 0;
    min-height: 48px;
    position: relative;
    background: #ffffff;
    border-bottom: 1px solid rgba(26, 40, 44, 0.15);
    border-radius: 2px 2px 0 0; }
    .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
      padding: 4px;
      position: relative;
      flex-grow: 1;
      height: 100%;
      cursor: pointer; }
      .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details.flex-row {
        flex-direction: row; }
      .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details.align-items-center {
        align-items: center; }
      .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details.ml1 {
        margin-left: 4px; }
      .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity {
        display: flex;
        position: relative; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity.presence-entity--size-1 {
          width: 32px;
          height: 32px; }
          .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity.presence-entity--size-1 .profile-image {
            width: 32px;
            height: 32px;
            background: #D9D9C9; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity .user-badge .initials {
          width: 32px;
          height: 32px; }
          .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity .user-badge .initials.H4DesktopWhite {
            font-size: 15px; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity .presence-entity__image {
          background-size: cover; }
          .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .presence-entity .presence-entity__image.EntityPhoto-circle-1 {
            width: 32px;
            height: 32px;
            box-sizing: border-box;
            background-clip: content-box;
            border: 3px solid transparent;
            border-radius: 49.9%; }
      .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button {
        max-width: 230px; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button.truncate {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button.ml2 {
          margin-left: 8px; }
        .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button h4 {
          color: #1a282c;
          font-weight: bold; }
          .msg-overlay-list-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__button h4.truncate {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
  .msg-overlay-list-bubble .msg-overlay-list-bubble-search {
    display: flex;
    flex-direction: column; }
    .msg-overlay-list-bubble .msg-overlay-list-bubble-search .msg-overlay-list-bubble-search__input-container {
      position: relative;
      background: 0 0; }
      .msg-overlay-list-bubble .msg-overlay-list-bubble-search .msg-overlay-list-bubble-search__input-container .msg-overlay-list-bubble-search__search-typeahead-input {
        background-color: #f6f6f6;
        margin: 8px 12px;
        width: calc(100% - 44px);
        padding: 10px 10px 10px 10px;
        border: 0px;
        font-size: 12px; }
  .msg-overlay-list-bubble .msg-overlay-list-bubble__content {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 0; }
    .msg-overlay-list-bubble .msg-overlay-list-bubble__content.scrollable {
      overflow-y: auto; }
    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container {
      height: 100%; }
      .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list {
        background-color: transparent;
        flex: 1; }
        .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item,
        .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item {
          height: 66px; }
          .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card,
          .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card {
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 83ms;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            left: 0; }
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card.msg-overlay-list-bubble__convo-card,
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card.msg-overlay-list-bubble__convo-card {
              display: flex;
              width: 100%;
              padding-left: 8px;
              height: inherit; }
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card.display-flex,
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card.display-flex {
              display: flex; }
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity,
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity {
              min-width: 48px;
              display: flex;
              justify-content: center;
              align-items: center; }
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity.msg-selectable-entity__entity,
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity.msg-selectable-entity__entity {
                transition: visibility 0.1s ease-in-out;
                position: absolute; }
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity,
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity {
                display: flex;
                position: relative; }
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity.presence-entity--size-3,
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity.presence-entity--size-3 {
                  width: 48px;
                  height: 48px; }
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__image,
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__image {
                  background-size: cover; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__image.EntityPhoto-circle-3,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__image.EntityPhoto-circle-3 {
                    width: 48px;
                    height: 48px;
                    box-sizing: border-box;
                    background-clip: content-box;
                    border: 2px solid transparent;
                    border-radius: 49.9%; }
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator,
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator {
                  position: absolute;
                  box-shadow: 0 0 0 2px #ffffff; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator {
                    border-radius: 50%;
                    background: #ffffff;
                    flex-shrink: 0;
                    transition: background 167ms ease-in-out;
                    box-sizing: border-box;
                    animation: fade-in 167ms ease-in; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--size-3,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--size-3 {
                    width: 12px;
                    height: 12px; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--is-reachable,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--is-reachable {
                    border: 2px solid #1E5558; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--is-online,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.presence-indicator--is-online {
                    background: #1E5558; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.hidden,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-selectable-entity .presence-entity .presence-entity__indicator.hidden {
                    display: none; }
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content,
            .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content {
              height: auto;
              padding: 8px 12px 8px 8px;
              display: flex;
              flex-grow: 1;
              vertical-align: top;
              border-bottom: 1px solid var(--warm-gray-30, #e6e9ec);
              justify-content: space-between;
              overflow: hidden; }
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content.overflow-hidden,
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content.overflow-hidden {
                overflow: hidden; }
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content.pl2,
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content.pl2 {
                padding-left: 8px; }
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper,
              .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper {
                width: 100%;
                flex-grow: 2; }
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper.fl,
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper.fl {
                  float: left; }
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row,
                .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row {
                  display: flex; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names {
                    flex: 1;
                    width: 0;
                    color: #1a282c;
                    font-weight: 500; }
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names.truncate,
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__participant-names.truncate {
                      white-space: nowrap !important;
                      overflow: hidden !important;
                      text-overflow: ellipsis !important; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble-item__time-stamp,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble-item__time-stamp {
                    margin-left: auto;
                    overflow: hidden;
                    white-space: nowrap;
                    color: #979797;
                    font-size: 12px;
                    line-height: 2; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container {
                    width: calc(100% - 18px); }
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2,
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2 {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      margin: 0 auto;
                      -webkit-font-smoothing: antialiased;
                      font-size: 12px;
                      line-height: normal; }
                      .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2.m0,
                      .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-overlay-list-bubble__message-snippet-container .msg-overlay-list-bubble__message-snippet--v2.m0 {
                        margin: 0; }
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status,
                  .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status {
                    display: flex;
                    justify-content: flex-end;
                    margin-right: auto;
                    text-align: right;
                    align-self: center;
                    align-items: center;
                    padding-left: 4px; }
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status.flex-grow-1,
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status.flex-grow-1 {
                      flex-grow: 1; }
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status.align-items-center,
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status.align-items-center {
                      align-items: center; }
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status .msg-conversation-card__unread-count,
                    .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status .msg-conversation-card__unread-count {
                      border-radius: 14px;
                      border: 2px solid #fff;
                      display: inline-block;
                      line-height: 0;
                      margin: 0;
                      opacity: 1;
                      padding: 0;
                      transform-origin: bottom left;
                      vertical-align: baseline;
                      width: auto;
                      animation-name: artdecoBadgeAnimationIn1, artdecoBadgeAnimationIn2;
                      animation-duration: 345ms, 255ms;
                      animation-delay: 0s, 345ms;
                      animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
                      background: #1C21DF; }
                      .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__convo-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status .msg-conversation-card__unread-count abbr,
                      .msg-overlay-list-bubble .msg-overlay-list-bubble__content .msg-overlay-list-bubble__default-conversation-container .msg-overlay-list-bubble__conversations-list .msg-overlay-list-bubble__profile-item .msg-conversation-card .msg-overlay-list-bubble__convo-card-content .msg-overlay-list-bubble__convo-card-content-wrapper .msg-conversation-card__row .msg-conversation-card__conversation-status .msg-conversation-card__unread-count abbr {
                        color: #fff;
                        border: none;
                        box-sizing: border-box;
                        clip: auto;
                        cursor: default;
                        display: inline-block;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 600;
                        height: 14px;
                        line-height: 14px;
                        min-width: 14px;
                        position: relative;
                        padding: 0 4px;
                        text-align: center;
                        text-decoration: none;
                        transition: min-width 0.15s, height 0.15s;
                        vertical-align: top; }

#user-conversation__new {
  z-index: 999;
  border: 1px solid #e6e9d7;
  max-width: 500px; }
  #user-conversation__new #task-modal-content .task-head_container {
    justify-content: center; }
  #user-conversation__new #task-modal-content .chat-select-user-footer {
    display: flex;
    justify-content: center;
    align-items: center; }
    #user-conversation__new #task-modal-content .chat-select-user-footer #footer-submit-btn {
      margin-right: 10px; }

.applicant-homepage #donor-header__chat-menu {
  background: transparent; }
