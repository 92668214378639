.notification-list__updates-filter-menu {
  position: relative; }
  .notification-list__updates-filter-menu .menu {
    top: 30px;
    right: 20px;
    min-width: 120px; }

.notification-list__subheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px; }

.notification-list__list .all-notifications {
  cursor: pointer; }
