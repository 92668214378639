#account-menu {
  display: flex;
  position: relative; }

#account-menu__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  min-width: 35px; }
  @media (max-width: 768.98px) {
    #account-menu__info {
      margin-right: 10px; } }
  #account-menu__info #account-menu__company-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px; }
    @media (max-width: 768.98px) {
      #account-menu__info #account-menu__company-name {
        max-width: 100px; } }

#account-menu__profile-icon {
  margin-right: 8px; }
  @media (max-width: 768.98px) {
    #account-menu__profile-icon {
      margin-left: 8px; } }

#account-menu__first-name {
  margin-bottom: -5px; }

#account-menu__menu {
  right: 0;
  top: 55px;
  min-width: 180px; }

#header__inner.header.RECOMMENDER #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }

#header__inner.header.VERIFIER #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }

#header__inner.header.NGB #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }

#header__inner.header.SCHOOLCOUNSELLOR #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }

#header__inner.header.ENDORSEDUSER #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }

#header__inner.header.THIRDPARTYCONTRIBUTOR #recommender-header__inner #account-menu #account-menu__info {
  margin-right: 50px; }
