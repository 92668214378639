#recommender-header__inner {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between; }
  #recommender-header__inner #donor-header__right {
    margin-right: unset; }
  #recommender-header__inner #account-menu {
    margin-right: 55px; }
  #recommender-header__inner .header__sholarship-name {
    width: 100%;
    margin: 27px 5px 29px 43px;
    font-family: 'Inter-Medium';
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff; }
    @media (max-width: 1023.98px) {
      #recommender-header__inner .header__sholarship-name {
        margin: 10px; } }

#recommender-header__brand-mark {
  margin-left: 40px;
  padding-right: 40px;
  border-right: 1px solid #FBFBF9;
  height: 100%;
  min-width: 40px; }
  @media (max-width: 479.98px) {
    #recommender-header__brand-mark {
      margin-left: 15px;
      padding-right: 15px; } }

#recommender-header__left {
  display: flex;
  align-items: center;
  height: 100%; }

#recommender-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 55px; }
  @media (max-width: 1023.98px) {
    #recommender-header__right {
      margin-right: 5px;
      width: 50%; } }
  #recommender-header__right span.recommender-header__close {
    cursor: pointer;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff; }
    @media (max-width: 1023.98px) {
      #recommender-header__right span.recommender-header__close {
        margin: 10px; } }
  #recommender-header__right .icon {
    margin-right: 33px; }
    #recommender-header__right .icon:last-child {
      margin-right: 0; }
