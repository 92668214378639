.donor-scholarships-sidebar {
  width: 100%;
  min-width: 238px;
  max-width: 238px;
  background: #FBFBF9;
  position: fixed;
  height: 100%; }
  .donor-scholarships-sidebar .scholarships-left-section {
    padding-top: 20px; }

.reviewer-screen-sidebar {
  padding-top: 80px !important; }
