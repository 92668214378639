.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack, .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__time-heading {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack, .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__title, .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__device-name-with-timestamp, .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-message-group__meta .msg-s-message-group__name, .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-message-group__meta .msg-s-message-group__timestamp, .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__message-bubble .msg-s-event-listitem__body {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey, .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable .msg-form__contenteditable p {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.open-particular-chatting {
  position: fixed;
  z-index: 999;
  height: 100vh;
  top: 0; }
  .open-particular-chatting .chat-window-2 {
    right: 740px; }

.msg-overlay-conversation-bubble {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  right: 400px;
  height: 400px;
  max-height: calc(100vh - 80px);
  width: 320px;
  background: #ffffff;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 167ms; }
  .msg-overlay-conversation-bubble.msg-overlay-conversation-bubble--is-minimized {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 167ms;
    transform: translateY(100%) translateY(-48px);
    width: 216px;
    bottom: 0; }
  .msg-overlay-conversation-bubble.msg-overlay-conversation-bubble--jumbo {
    height: 696px;
    width: 500px; }
  .msg-overlay-conversation-bubble .msg-overlay-bubble-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px 0 8px;
    min-height: 48px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px 2px 0 0;
    background: #1E5558; }
    .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image {
      display: flex;
      position: relative; }
      .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__image {
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        background-clip: content-box;
        border: 3px solid transparent;
        border-radius: 49.9%; }
      .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .user-badge .initials {
        width: 32px;
        height: 32px; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .user-badge .initials.H4DesktopWhite {
          font-size: 15px; }
      .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator {
        position: absolute;
        box-shadow: 0 0 0 2px #ffffff; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator.presence-indicator {
          border-radius: 50%;
          background: #ffffff;
          flex-shrink: 0;
          transition: background 167ms ease-in-out;
          box-sizing: border-box;
          animation: fade-in 167ms ease-in; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator.presence-indicator--is-reachable {
          border: 2px solid #1E5558; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator.presence-indicator--size-1 {
          width: 8px;
          height: 8px; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator.presence-indicator--is-online {
          background: #1E5558; }
        .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__profile-image .presence-entity__indicator.hidden {
          display: none; }
    .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
      padding: 4px;
      position: relative;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
      align-items: center; }
      .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__title {
        color: #ffffff;
        font-weight: bold; }
      .msg-overlay-conversation-bubble .msg-overlay-bubble-header .msg-overlay-bubble-header__details .msg-overlay-bubble-header__device-name-with-timestamp {
        font-size: 11px;
        color: #ffffff; }
  .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper {
    flex-grow: 1;
    height: 0; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper.relative {
      position: relative; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper.flex-column {
      flex-direction: column; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper.display-flex {
      display: flex; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container {
      flex-direction: column;
      overflow-y: hidden;
      flex: 1 0 0px; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container.relative {
        position: relative; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container.display-flex {
        display: flex; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container.mtA,
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .mvA {
        margin-top: auto; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list {
        min-height: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list.full-width {
          width: 100%; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content {
          min-height: auto;
          padding: 16px 0 0; }
          .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content.full-width {
            width: 100%; }
          .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__time-heading {
            align-items: center;
            clear: both;
            display: flex;
            letter-spacing: 1px;
            margin: 8px 0;
            text-transform: uppercase;
            font-style: normal; }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__time-heading::after {
              display: block;
              content: "";
              flex-grow: 1;
              border-top: 1px solid rgba(0, 0, 0, 0.15); }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__time-heading::before {
              display: block;
              content: "";
              flex-grow: 1;
              border-top: 1px solid rgba(0, 0, 0, 0.15); }
          .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem {
            display: flex;
            flex-direction: column;
            list-style: none;
            box-sizing: border-box;
            position: relative;
            padding: 0;
            margin: 0;
            width: 100%;
            margin-bottom: 12px; }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__link {
              position: absolute;
              left: 8px;
              z-index: 1; }
              .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__link .msg-s-event-listitem__profile-picture {
                position: static;
                top: 0;
                left: 0;
                background-size: cover; }
                .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__link .msg-s-event-listitem__profile-picture.EntityPhoto-circle-2 {
                  width: 40px;
                  height: 40px;
                  box-sizing: border-box;
                  background-clip: content-box;
                  border: 3px solid transparent;
                  border-radius: 49.9%; }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-message-group__meta {
              line-height: 1;
              margin-bottom: 4px;
              padding: 0 0 0 65px; }
              .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-message-group__meta .msg-s-message-group__name {
                margin-right: 5px;
                font-weight: bold; }
              .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-message-group__meta .msg-s-message-group__timestamp {
                font-size: 11px; }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__message-bubble {
              display: inline-block;
              max-width: 100%;
              padding: 0;
              position: relative; }
              .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-s-message-list-container .msg-s-message-list .msg-s-message-list-content .msg-s-message-list__event .msg-s-event-listitem .msg-s-event-listitem__message-bubble .msg-s-event-listitem__body {
                padding-right: 12px;
                margin: 4px 0 4px 65px;
                word-wrap: break-word;
                white-space: pre-line;
                -webkit-font-smoothing: antialiased;
                color: #1a282c;
                line-height: normal; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form {
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 167ms;
      background: #ffffff;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      max-height: 100%;
      position: static;
      transition-property: all;
      width: 100%; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;
        position: relative; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container::before {
          transition-property: transform;
          background: linear-gradient(to right, var(--blue-70, #0073b1) 50%, rgba(0, 0, 0, 0.1) 50%);
          content: "";
          height: 2px;
          position: absolute;
          transform: translateX(-50%);
          top: 0;
          width: 200%;
          z-index: 1;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 167ms; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          height: 100%;
          max-height: 120px;
          overflow: auto; }
          .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable .flex-grow-1 {
            flex-grow: 1 !important; }
          .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable .msg-form__contenteditable {
            min-height: 60px;
            overflow: auto;
            padding: 12px 0px 0 12px; }
            .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable .msg-form__contenteditable #write-a-message-textarea {
              margin-bottom: 0px; }
              .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__msg-content-container .msg-form__msg-content-container--scrollable .msg-form__contenteditable #write-a-message-textarea textarea {
                border: 0px;
                padding: 5px;
                margin-top: 0px;
                margin-bottom: 0px;
                height: 60px;
                font-size: 14px;
                width: 100%;
                overflow: hidden; }
      .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__footer {
        border-top: 1px solid #cdcfd2;
        justify-content: space-between;
        margin-top: auto;
        padding: 4px 8px;
        align-items: center;
        display: flex; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__footer.flex-shrink-zero {
          flex-shrink: 0; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__footer .msg-form__left-actions.display-flex {
          display: flex; }
        .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form .msg-form__footer .msg-form__right-actions #chat-message-send-button {
          padding: 10px 12px;
          font-size: 12px; }
    .msg-overlay-conversation-bubble .msg-overlay-conversation-bubble__content-wrapper .msg-form__message-texteditor {
      flex: 1 0 auto; }

.applicant-right-side__content-area.side-drawer-menu-large-space #donor-header__chat-menu {
  width: 440px; }

.applicant-right-side__content-area.side-drawer-menu-large-space .open-particular-chatting {
  position: unset; }
  .applicant-right-side__content-area.side-drawer-menu-large-space .open-particular-chatting .msg-overlay-conversation-bubble.chat-window-1 {
    right: 450px;
    z-index: 999; }
  .applicant-right-side__content-area.side-drawer-menu-large-space .open-particular-chatting .msg-overlay-conversation-bubble.chat-window-2 {
    right: 780px;
    z-index: 999; }

.applicant-right-side__content-area.side-drawer-menu-small-space #donor-header__chat-menu {
  width: 440px; }

.applicant-right-side__content-area.side-drawer-menu-small-space .open-particular-chatting {
  position: unset; }
  .applicant-right-side__content-area.side-drawer-menu-small-space .open-particular-chatting .msg-overlay-conversation-bubble.chat-window-1 {
    right: 450px;
    z-index: 999; }
  .applicant-right-side__content-area.side-drawer-menu-small-space .open-particular-chatting .msg-overlay-conversation-bubble.chat-window-2 {
    right: 780px;
    z-index: 999; }
