.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey, .dashboard-statistics .cards .card-single .counter-title, .dashboard-statistics .cards-list .card-list-single__items .card-list-single__title, .dashboard-statistics .cards-list .card-list-single .full-report, .email-history-menu .email-history .timeline__event__description p {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack, .dashboard-statistics .cards .card-single .counter {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite, .promote-user-menu .promote-user .email-address-or-upload-file-container .upload-csv .download-sample-link, .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container .enter-email-address .download-sample-link {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  width: auto; }

#nav-toggle {
  display: none; }

.admin-sidebar {
  width: 285px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #1C21DF;
  border-right: 1px solid #D9D9C9;
  z-index: 9999999;
  overflow-y: auto;
  transition: margin-left 300ms; }
  .admin-sidebar__brand-container {
    padding: 30px;
    width: auto; }
    .admin-sidebar__brand-container_welcome-message {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .admin-sidebar__brand-container_welcome-message__message {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .admin-sidebar__brand-container_welcome-message__message h1 {
          font-size: 16px; }
      .admin-sidebar__brand-container_welcome-message__logo {
        margin-right: 10px; }
        .admin-sidebar__brand-container_welcome-message__logo .only-icon-logo {
          display: none; }
      .admin-sidebar__brand-container_welcome-message__toggle-icon label {
        cursor: pointer; }
  .admin-sidebar__menu-container .admin-sidebar__menu__item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 20px; }
    .admin-sidebar__menu-container .admin-sidebar__menu__item.active {
      background: #ffffff;
      padding: 10px 10px 10px 20px;
      border-radius: 30px 0px 0px 30px; }
    .admin-sidebar__menu-container .admin-sidebar__menu__item svg {
      margin-right: 10px; }

.H5HeadingDivider {
  margin: 16px 0 41px 0px;
  border: dashed 1px #e6e9d7; }

#nav-toggle:checked + .admin-sidebar {
  width: 100px;
  transition: margin-left 300ms; }
  #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container {
    padding: 30px 10px 30px 10px; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container .full-logo {
      display: none; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container .only-icon-logo {
      display: unset; }
  #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item {
    margin-left: 0px;
    display: block;
    text-align: center;
    padding: 10px; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item svg {
      margin-right: 0px; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item .side-drawer-menu-list__item-copy {
      display: none; }

#nav-toggle:checked ~ .admin-main-contain {
  transition: margin-left 300ms;
  margin-left: 100px; }

.admin-main-contain {
  transition: margin-left 300ms;
  margin-left: 285px; }
  .admin-main-contain.admin-main-contain-expand {
    transition: margin-left 300ms;
    margin-left: 100px; }
    .admin-main-contain.admin-main-contain-expand .admin-header {
      left: 100px;
      width: calc(100% - 99px); }
  .admin-main-contain .table__container {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
  .admin-main-contain .skeleton-loader__container {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #ffffff; }
  .admin-main-contain .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    position: fixed;
    left: 285px;
    width: calc(100% - 286px);
    top: 0px;
    z-index: 9999;
    border-bottom: 0px solid #D9D9C9;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
    .admin-main-contain .admin-header .date {
      margin-bottom: 5px; }
    .admin-main-contain .admin-header h2 {
      display: none; }
      .admin-main-contain .admin-header h2 label {
        background: #1C21DF;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        cursor: pointer; }
        .admin-main-contain .admin-header h2 label:hover {
          background: #1a282c; }
    .admin-main-contain .admin-header__search-wrapper__search-input {
      margin-bottom: 0px; }
      .admin-main-contain .admin-header__search-wrapper__search-input .search {
        margin-top: 0px;
        height: 55px; }
      .admin-main-contain .admin-header__search-wrapper__search-input .field__search-icon {
        top: 15px; }
    .admin-main-contain .admin-header__user-wrapper {
      display: flex;
      align-items: center;
      width: auto; }
      .admin-main-contain .admin-header__user-wrapper .CTAGrey, .admin-main-contain .admin-header__user-wrapper .dashboard-statistics .cards .card-single .counter-title, .dashboard-statistics .cards .card-single .admin-main-contain .admin-header__user-wrapper .counter-title, .admin-main-contain .admin-header__user-wrapper .dashboard-statistics .cards-list .card-list-single__items .card-list-single__title, .dashboard-statistics .cards-list .card-list-single__items .admin-main-contain .admin-header__user-wrapper .card-list-single__title, .admin-main-contain .admin-header__user-wrapper .dashboard-statistics .cards-list .card-list-single .full-report, .dashboard-statistics .cards-list .card-list-single .admin-main-contain .admin-header__user-wrapper .full-report, .admin-main-contain .admin-header__user-wrapper .email-history-menu .email-history .timeline__event__description p, .email-history-menu .email-history .timeline__event__description .admin-main-contain .admin-header__user-wrapper p {
        min-width: 150px; }
      .admin-main-contain .admin-header__user-wrapper .user-badge {
        margin-right: 10px; }
      .admin-main-contain .admin-header__user-wrapper h4 {
        text-transform: capitalize; }
      .admin-main-contain .admin-header__user-wrapper img {
        border-radius: 100%;
        margin-right: 1rem; }
  .admin-main-contain .admin-main {
    background: #f6f6f6;
    padding: 1rem;
    width: auto; }
    .admin-main-contain .admin-main .admin-welcome-message {
      margin-bottom: 35px;
      margin-top: 10px;
      text-transform: capitalize; }
      .admin-main-contain .admin-main .admin-welcome-message svg {
        margin-right: 10px; }
    .admin-main-contain .admin-main .table__responsive {
      overflow-x: auto;
      overflow-y: hidden; }
      .admin-main-contain .admin-main .table__responsive .user-badge {
        margin-right: 10px; }

@media only screen and (max-width: 768px) {
  .admin-sidebar {
    left: -100% !important; }
  .admin-header h2 {
    display: unset !important; }
    .admin-header h2 label {
      background: #1C21DF;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      cursor: pointer; }
  .admin-header h1 .date {
    display: none; }
  .admin-header h1 .time {
    display: none; }
  .admin-header__search-wrapper {
    display: none; }
  .admin-main-contain {
    width: 100%;
    margin-left: 0rem; }
    .admin-main-contain .admin-header {
      width: 100%;
      left: 0;
      z-index: 9999999; }
    .admin-main-contain .admin-main {
      margin-top: 0px; }
  #nav-toggle:checked + .admin-sidebar {
    left: 0 !important;
    width: 100%;
    top: 70px; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container {
      padding: 30px; }
      #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container h2 label svg {
        display: none; }
      #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container .full-logo {
        display: none; }
      #nav-toggle:checked + .admin-sidebar .admin-sidebar__brand-container .only-icon-logo {
        display: none; }
    #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item {
      margin-left: 10px;
      display: flex;
      text-align: center;
      padding: 10px 10px 10px 20px; }
      #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item svg {
        margin-right: 10px; }
      #nav-toggle:checked + .admin-sidebar .admin-sidebar__menu .admin-sidebar__menu__item .side-drawer-menu-list__item-copy {
        display: unset; } }

.admin-login-menu {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px; }
  @media (max-width: 768.98px) {
    .admin-login-menu {
      grid-template-columns: 1fr; } }
  .admin-login-menu .logged-in-As {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
    .admin-login-menu .logged-in-As .H5DesktopGreen {
      display: flex;
      align-items: center; }
      .admin-login-menu .logged-in-As .H5DesktopGreen svg {
        margin-right: 10px; }
    .admin-login-menu .logged-in-As .footer-btn {
      display: flex;
      align-items: center;
      justify-content: center; }
      .admin-login-menu .logged-in-As .footer-btn #search-user {
        margin-right: 10px; }
  .admin-login-menu .user-password-reset-link {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
    .admin-login-menu .user-password-reset-link .footer-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .admin-login-menu .user-password-reset-link .footer-btn #get-link {
        margin-right: 10px; }

.logged-in-As-output {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: auto; }
  .logged-in-As-output table thead tr {
    border-bottom: 1px dashed #D9D9C9; }
    .logged-in-As-output table thead tr th {
      font-size: 14px; }
  .logged-in-As-output table tbody tr .heroku-user-id-highlight {
    border-radius: 5px;
    min-height: 40px;
    min-width: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #ffa3d1;
    font-family: 'Inter-Medium'; }
  .logged-in-As-output table tbody tr:last-child {
    border-bottom: 0px; }
  .logged-in-As-output .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center; }

.user-password-reset-link-output {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .user-password-reset-link-output .footer-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.heroku-user-id-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px; }
  @media (max-width: 768.98px) {
    .heroku-user-id-menu {
      grid-template-columns: 1fr; } }
  .heroku-user-id-menu .heroku-user-id {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
    .heroku-user-id-menu .heroku-user-id .footer-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .heroku-user-id-menu .heroku-user-id .footer-btn #search-user {
        margin-right: 10px; }
  .heroku-user-id-menu .heroku-user-id-output {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow-x: auto; }
    .heroku-user-id-menu .heroku-user-id-output table tbody tr .EyebrowGrey {
      font-size: 14px; }
    .heroku-user-id-menu .heroku-user-id-output table tbody tr .heroku-user-id-highlight {
      border-radius: 5px;
      min-height: 40px;
      min-width: 40px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #ffa3d1;
      font-family: 'Inter-Medium'; }

.promote-user-menu .promote-user {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .promote-user-menu .promote-user .table__responsive {
    overflow-x: auto;
    overflow-y: hidden; }
    .promote-user-menu .promote-user .table__responsive .user-badge {
      margin-right: 10px; }
  .promote-user-menu .promote-user .promote-users-records-table .status-completed {
    border: 1px solid #1E5558;
    padding: 5px 10px;
    border-radius: 5px;
    background: #1E5558;
    color: #ffffff; }
  .promote-user-menu .promote-user .promote-users-records-table .status-failed {
    border: 1px solid #FF542D;
    padding: 5px 10px;
    border-radius: 5px;
    background: #FF542D;
    color: #ffffff; }
  .promote-user-menu .promote-user .promote-users-records-table .failed-records {
    display: flex;
    align-items: center; }
    .promote-user-menu .promote-user .promote-users-records-table .failed-records svg {
      margin-right: 5px;
      margin-top: 4px; }
  .promote-user-menu .promote-user .counts {
    border-radius: 5px;
    min-height: 30px;
    min-width: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #ffa3d1;
    font-family: 'Inter-Medium';
    margin-left: 10px;
    color: #1a282c; }
  .promote-user-menu .promote-user .email-address-or-upload-file-container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 15px;
    align-self: center;
    align-items: center; }
    @media (max-width: 768.98px) {
      .promote-user-menu .promote-user .email-address-or-upload-file-container {
        grid-template-columns: 1fr; } }
    .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role {
      margin-bottom: 0px; }
      .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .CTAGrey .H6DesktopBlack > div, .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .dashboard-statistics .cards .card-single .counter-title .H6DesktopBlack > div, .dashboard-statistics .cards .card-single .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .counter-title .H6DesktopBlack > div, .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .dashboard-statistics .cards-list .card-list-single__items .card-list-single__title .H6DesktopBlack > div, .dashboard-statistics .cards-list .card-list-single__items .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .card-list-single__title .H6DesktopBlack > div, .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .dashboard-statistics .cards-list .card-list-single .full-report .H6DesktopBlack > div, .dashboard-statistics .cards-list .card-list-single .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .full-report .H6DesktopBlack > div, .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role .email-history-menu .email-history .timeline__event__description p .H6DesktopBlack > div, .email-history-menu .email-history .timeline__event__description .promote-user-menu .promote-user .email-address-or-upload-file-container .enter-email-address #user-role p .H6DesktopBlack > div {
        z-index: 6; }
    .promote-user-menu .promote-user .email-address-or-upload-file-container .or-container {
      display: flex;
      align-items: center;
      justify-content: center; }
      .promote-user-menu .promote-user .email-address-or-upload-file-container .or-container .or-divider {
        border-radius: 5px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #ffa3d1;
        font-family: 'Inter-Medium'; }
    .promote-user-menu .promote-user .email-address-or-upload-file-container .upload-csv .download-sample-link {
      border-radius: 5px;
      box-sizing: border-box;
      padding: 20px 24px;
      align-items: center;
      transition: transform 0.25s;
      background: #1a282c;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 280px; }
      .promote-user-menu .promote-user .email-address-or-upload-file-container .upload-csv .download-sample-link:hover {
        transform: scale(1.04);
        transition: transform 0.25s; }
      .promote-user-menu .promote-user .email-address-or-upload-file-container .upload-csv .download-sample-link .download-icon {
        margin-right: 10px; }
  .promote-user-menu .promote-user .send-magic-link-email {
    margin-top: 25px; }
    .promote-user-menu .promote-user .send-magic-link-email .checkbox-label {
      margin-left: 10px; }
  .promote-user-menu .promote-user .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px; }
    .promote-user-menu .promote-user .footer-btn #search-user {
      margin-right: 10px; }
  .promote-user-menu .promote-user .no-recommender-record {
    color: red; }

.dashboard-statistics {
  box-sizing: border-box; }
  .dashboard-statistics .active-users-cards {
    margin-bottom: 15px;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    padding-bottom: 60px; }
    .dashboard-statistics .active-users-cards .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dashboard-statistics .active-users-cards .header-container #search .cta-button__text {
        display: flex;
        align-items: center;
        justify-content: center; }
        .dashboard-statistics .active-users-cards .header-container #search .cta-button__text svg {
          margin-right: 10px; }
    .dashboard-statistics .active-users-cards .table__container {
      box-shadow: none;
      padding: unset; }
  .dashboard-statistics .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    margin-bottom: 15px; }
    @media (max-width: 768.98px) {
      .dashboard-statistics .cards {
        grid-template-columns: 1fr; } }
    .dashboard-statistics .cards .card-single {
      background: #ffffff;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px 20px 20px 20px; }
      .dashboard-statistics .cards .card-single .table__container {
        box-shadow: none; }
      .dashboard-statistics .cards .card-single .doughnut-browser-chart {
        max-width: 500px;
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0 auto; }
      .dashboard-statistics .cards .card-single .table__responsive {
        overflow-x: auto;
        overflow-y: hidden; }
      .dashboard-statistics .cards .card-single .active-users-table .user-badge {
        margin-right: 10px; }
      .dashboard-statistics .cards .card-single .title-with-icons {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .dashboard-statistics .cards .card-single .title-with-icons .counts {
          border-radius: 5px;
          min-height: 30px;
          min-width: 30px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0px;
          padding-bottom: 0px;
          background: #ffa3d1;
          font-family: 'Inter-Medium';
          margin-left: 10px;
          color: #1a282c; }
        .dashboard-statistics .cards .card-single .title-with-icons img {
          width: 25px; }
      .dashboard-statistics .cards .card-single .counter {
        font-size: 28px;
        font-style: normal;
        margin-bottom: 5px; }
  .dashboard-statistics .cards-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-bottom: 15px; }
    @media (max-width: 768.98px) {
      .dashboard-statistics .cards-list {
        grid-template-columns: 1fr; } }
    .dashboard-statistics .cards-list .card-list-single {
      background: #ffffff;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .dashboard-statistics .cards-list .card-list-single__items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px; }
        .dashboard-statistics .cards-list .card-list-single__items .card-list-single_count {
          border-radius: 5px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: #ffa3d1;
          font-family: 'Inter-Medium';
          box-sizing: border-box; }
      .dashboard-statistics .cards-list .card-list-single .full-report {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;
        font-size: 12px; }

.update-recommender-menu .relationship-icon {
  margin-right: 5px; }

.update-recommender-menu .update-recommender {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .update-recommender-menu .update-recommender .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center; }
    .update-recommender-menu .update-recommender .footer-btn #search {
      margin-right: 10px; }

.user-password-reset-menu .user-password-reset {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 15px;
    align-self: center;
    align-items: center; }
    .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container .enter-email-address .download-sample-link {
      border-radius: 5px;
      box-sizing: border-box;
      padding: 20px 24px;
      align-items: center;
      transition: transform 0.25s;
      background: #1a282c;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 280px; }
      .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container .enter-email-address .download-sample-link:hover {
        transform: scale(1.04);
        transition: transform 0.25s; }
      .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container .enter-email-address .download-sample-link .download-icon {
        margin-right: 10px; }
    @media (max-width: 768.98px) {
      .user-password-reset-menu .user-password-reset .user-password-reset-using-csv-upload-file-container {
        grid-template-columns: 1fr; } }
  .user-password-reset-menu .user-password-reset .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px; }
    .user-password-reset-menu .user-password-reset .footer-btn #update-password {
      margin-right: 10px; }

.user-activities-menu {
  width: auto; }
  .user-activities-menu .user-activities {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: auto; }
    .user-activities-menu .user-activities .section .span_12_of_12 .donor-scholarships-page__top-copy .filter-selector .filter-selector__items .filter-selector__leading .filter-selector-item .filter-selector-item__btn {
      border: 1px solid;
      background-color: unset; }
    .user-activities-menu .user-activities .section .span_12_of_12 .donor-scholarships-page__top-copy .filter-selector .filter-selector__items .filter-selector__leading .filter-selector-item .filter-selector-item__dropdown {
      top: 62px; }
    .user-activities-menu .user-activities .footer-btn {
      display: flex;
      align-items: center;
      justify-content: center; }
      .user-activities-menu .user-activities .footer-btn #get-activities {
        margin-right: 10px; }
  .user-activities-menu .email-history-menu {
    width: auto; }
    .user-activities-menu .email-history-menu .timeline .timeline__event::before {
      display: none; }
    .user-activities-menu .email-history-menu .timeline .view-email-details__output {
      word-break: break-all;
      overflow-x: auto;
      margin: 0 auto; }
      .user-activities-menu .email-history-menu .timeline .view-email-details__output body div {
        width: auto !important; }
      .user-activities-menu .email-history-menu .timeline .view-email-details__output table tbody td {
        white-space: normal; }
    .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 7px;
      padding-top: 7px;
      border-bottom: 1px dashed #D9D9C9; }
      .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__key {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 150px; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__key svg {
          min-width: 30px; }
      .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value {
        display: flex;
        align-items: center;
        flex: 4;
        word-break: break-word; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value.sf_message {
          word-break: break-all;
          overflow: auto; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value pre {
          font-family: 'Inter-Medium'; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value .location-container {
          white-space: break-spaces;
          background-color: #1a282c;
          color: #ffffff;
          padding: 10px;
          border-radius: 5px;
          line-height: 25px;
          font-size: 17px;
          max-height: 400px;
          overflow-y: auto; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value table thead th {
          padding: 8px 8px 8px 8px;
          font-weight: bold; }
          .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value table thead th:first-child {
            padding-left: 0px; }
        .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value table tbody td {
          padding: 8px 8px 8px 8px; }
          .user-activities-menu .email-history-menu .timeline .view-email-details .key-and-value-container__value table tbody td:first-child {
            padding-left: 0px; }
    .user-activities-menu .email-history-menu .paginator__container {
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px; }
      .user-activities-menu .email-history-menu .paginator__container .paginator {
        margin: 20px auto; }

.client-users-menu .client-users {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .client-users-menu .client-users .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center; }
    .client-users-menu .client-users .footer-btn #search {
      margin-right: 10px; }

.review-board-users-menu .review-board-users {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .review-board-users-menu .review-board-users .footer-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .review-board-users-menu .review-board-users .footer-btn #search {
      margin-right: 10px; }

.analytics-data-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px; }
  @media (max-width: 768.98px) {
    .analytics-data-menu {
      grid-template-columns: 1fr; } }
  .analytics-data-menu .browser {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
  .analytics-data-menu .device-type {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
  .analytics-data-menu .operating-system {
    background: #ffffff;
    border: 0px solid #D9D9C9;
    padding: 20px;
    margin-bottom: 15px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px; }

.login-history-menu .login-history {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .login-history-menu .login-history .table__container {
    box-shadow: none; }
  .login-history-menu .login-history .H5DesktopGreen {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .login-history-menu .login-history .H5DesktopGreen #search .cta-button__text {
      display: flex;
      align-items: center;
      justify-content: center; }
      .login-history-menu .login-history .H5DesktopGreen #search .cta-button__text svg {
        margin-right: 10px; }

.email-history-menu .email-history {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: auto; }
  .email-history-menu .email-history .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center; }
    .email-history-menu .email-history .footer-btn #search {
      margin-right: 10px; }
  .email-history-menu .email-history .timeline {
    display: flex;
    flex-direction: column;
    width: 60vw;
    margin: 30px auto; }
    .email-history-menu .email-history .timeline__event {
      background: #fff;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      margin: 20px 0;
      border-radius: 5px;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025); }
      .email-history-menu .email-history .timeline__event.view-email-details {
        border-radius: 5px;
        padding: 20px;
        font-family: 'Inter-Medium';
        margin-top: 5px;
        margin-bottom: 5px;
        width: auto !important; }
        .email-history-menu .email-history .timeline__event.view-email-details pre {
          font-family: 'Inter-Medium'; }
        .email-history-menu .email-history .timeline__event.view-email-details__output {
          word-break: break-all; }
        .email-history-menu .email-history .timeline__event.view-email-details p {
          margin-bottom: 10px; }
      .email-history-menu .email-history .timeline__event__title {
        font-size: 18px; }
        .email-history-menu .email-history .timeline__event__title .email-subject {
          font-size: 18px;
          word-break: break-all; }
      .email-history-menu .email-history .timeline__event__content {
        padding: 20px;
        font-family: 'Inter-Medium';
        flex: 4; }
      .email-history-menu .email-history .timeline__event__expand {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex: 0.5; }
        .email-history-menu .email-history .timeline__event__expand svg {
          cursor: pointer; }
      .email-history-menu .email-history .timeline__event__date {
        color: #1E5558;
        font-size: 1rem;
        font-weight: normal;
        white-space: nowrap;
        font-family: 'Inter-Medium'; }
      .email-history-menu .email-history .timeline__event__icon {
        border-radius: 8px 0 0 8px;
        background: #D9D9C9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 40%;
        font-size: 2rem;
        color: #D9D9C9;
        padding: 20px;
        flex: 1; }
        .email-history-menu .email-history .timeline__event__icon svg {
          margin-right: 5px; }
        .email-history-menu .email-history .timeline__event__icon .date {
          margin-bottom: 5px; }
        .email-history-menu .email-history .timeline__event__icon i {
          position: absolute;
          top: 50%;
          left: -65px;
          font-size: 2.5rem;
          transform: translateY(-50%); }
      .email-history-menu .email-history .timeline__event__description {
        flex-basis: 60%;
        display: flex;
        align-items: center; }
        .email-history-menu .email-history .timeline__event__description svg {
          margin-left: -3px;
          margin-right: 3px; }
        .email-history-menu .email-history .timeline__event__description p {
          font-family: 'Inter-Medium';
          font-style: normal; }
      .email-history-menu .email-history .timeline__event:after {
        content: "";
        width: 2px;
        height: 100%;
        background: #D9D9C9;
        position: absolute;
        top: 52%;
        left: -3.5rem;
        z-index: -1; }
      .email-history-menu .email-history .timeline__event:last-child:after {
        content: none; }
  @media (max-width: 786px) {
    .email-history-menu .email-history .timeline__event {
      flex-direction: column; }
    .email-history-menu .email-history .timeline__event__icon {
      border-radius: 4px 4px 0 0; } }

.duplicate-contacts-menu .duplicate-contacts {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .duplicate-contacts-menu .duplicate-contacts .table__container {
    box-shadow: none; }
  .duplicate-contacts-menu .duplicate-contacts .footer-btn {
    display: flex;
    align-items: center;
    justify-content: center; }
    .duplicate-contacts-menu .duplicate-contacts .footer-btn #search {
      margin-right: 10px; }

.duplicate-contacts-menu .duplicate-contacts-table {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .duplicate-contacts-menu .duplicate-contacts-table #delete-user {
    padding: 12px 24px 12px 16px; }
    .duplicate-contacts-menu .duplicate-contacts-table #delete-user .cta-button__text {
      display: flex;
      align-items: center;
      justify-content: center; }
      .duplicate-contacts-menu .duplicate-contacts-table #delete-user .cta-button__text svg {
        margin-right: 5px; }
  .duplicate-contacts-menu .duplicate-contacts-table .title-with-icons .counts {
    border-radius: 5px;
    min-height: 30px;
    min-width: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #ffa3d1;
    font-family: 'Inter-Medium';
    margin-left: 10px;
    color: #1a282c; }

.frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync {
  background: #ffffff;
  border: 0px solid #D9D9C9;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: auto; }
  .frontend-to-salesforce-sync-menu .frontend-to-salesforce-sync .admin-table__record-per-page {
    max-width: 170px;
    margin-left: auto; }

#copyleaks-scan-logo {
  margin-right: 10px !important;
  border-radius: 50%; }
