.donor-sidebar {
  width: 100%;
  min-width: 238px;
  max-width: 238px;
  background: #FBFBF9;
  position: fixed;
  height: 100%;
  top: 80px;
  border-right: 1px solid #d3d3d3; }
  .donor-sidebar-left-section {
    padding-top: 20px; }
    .donor-sidebar-left-section .icon-with-heading {
      margin-bottom: 20px;
      padding-left: 65px;
      display: flex;
      align-items: center; }
      .donor-sidebar-left-section .icon-with-heading h1 {
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.5px;
        color: #1a282c; }
      .donor-sidebar-left-section .icon-with-heading img {
        margin-right: 5px; }
    .donor-sidebar-left-section ul li {
      font-weight: normal;
      cursor: pointer;
      color: #1a282c;
      padding: 0.5rem;
      padding-left: 4.125rem; }
      .donor-sidebar-left-section ul li:hover {
        background: #35888D;
        color: #ffffff; }
      .donor-sidebar-left-section ul li.active-current-page {
        background: #1E5558;
        color: #ffffff;
        font-style: italic; }
    .donor-sidebar-left-section ul li.CTAWhite.disable {
      color: #979797; }
      .donor-sidebar-left-section ul li.CTAWhite.disable:hover {
        color: #ffffff; }
