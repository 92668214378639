.dot-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px; }
  .dot-menu .run-export {
    margin-right: 10px;
    padding: 10px;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: #1C21DF;
    border: 1px solid #1C21DF;
    letter-spacing: normal;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5; }
  .dot-menu:hover svg g {
    fill: #1C21DF; }
  .dot-menu.horizontal {
    padding: 10px; }
    .dot-menu.horizontal .dot-menu__menu {
      top: 25px;
      padding: 20px; }
      .dot-menu.horizontal .dot-menu__menu .menu__item {
        width: 100%;
        text-align: left; }
        .dot-menu.horizontal .dot-menu__menu .menu__item .menu__link {
          width: 100%;
          display: block; }
  .dot-menu.vertical {
    transform: rotate(90deg); }

.dot-menu__menu {
  right: -13px;
  top: 6px;
  min-width: 100px; }
