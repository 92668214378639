.status-cell-type__dot {
  display: inline-block;
  height: 12px;
  width: 12px;
  background-color: #1C21DF;
  border-radius: 50%;
  margin-right: 10px; }
  .status-cell-type__dot.Draft {
    background-color: #ffcd00; }
  .status-cell-type__dot.Closed {
    background-color: #FF542D; }
  .status-cell-type__dot.Accepting.Applications {
    background-color: #1E5558; }
  .status-cell-type__dot.Prototype {
    background-color: #008291; }
  .status-cell-type__dot.Review.Board {
    background-color: #1C21DF; }
  .status-cell-type__dot.Submitted {
    background: #0076c2; }
