.no-data-to-display {
  padding: 40px 24px;
  background-color: #ffffff; }

.no-data-to-display__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 306px; }

.no-data-to-display__illustration {
  display: block;
  width: 195px;
  height: auto; }

.no-data-to-display__description {
  margin: 23px 0 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: normal; }

.no-data-to-display__cta {
  margin: 40px auto 0; }
