.modal-utility .sweet-alert {
  padding: 32px 32px 16px 32px !important;
  width: 400px !important;
  border: 1px solid #1a282c !important; }
  @media (min-width: 769px) {
    .modal-utility .sweet-alert {
      width: auto; } }
  .modal-utility .sweet-alert .content {
    padding: 0 18px;
    text-align: left; }
    .modal-utility .sweet-alert .content .CTAgrey {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding: 10px 0px 8px; }
  .modal-utility .sweet-alert .action-footer {
    width: 100%;
    display: flex;
    justify-content: right;
    padding-bottom: 6px;
    height: auto; }
    .modal-utility .sweet-alert .action-footer button {
      padding: 10px 40px;
      font-size: 16px;
      height: fit-content; }
  .modal-utility .sweet-alert h2 {
    margin: 0; }
  .modal-utility .sweet-alert .title {
    line-height: 24px;
    font-family: 'Inter-Regular', sans-serif;
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px; }
