.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack, .dropdown-menu.multi-level-select > li {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack, .dropdown-menu.multi-level-select > li {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault, .dropdown-menu.multi-level-select {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.dropdown-menu.multi-level-select {
  position: inherit;
  min-width: 100%;
  height: 50px;
  z-index: 999;
  float: none;
  display: block;
  background-color: #f8f8f8;
  text-align: left;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  margin: 0 0 15px 0;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid #1E5558;
  cursor: pointer; }

.dropdown-menu.multi-level-select > li {
  padding: 0 1rem;
  border-radius: 4px;
  line-height: 50px; }

.step-input .dropdown-menu {
  display: block; }

.ques-desc {
  font-size: 13px;
  margin: 10px 0px 10px 0px;
  line-height: normal; }

.dropdown-menu {
  height: 35px;
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  text-align: center;
  color: #000; }

.form-group.select-field .form-error,
.form-group.input-field .form-error {
  position: initial; }

.form-group.showy-label .select-field .form-error,
.form-group.static-label .select-field .form-error {
  position: initial; }

/*multi level menu css*/
.dropdown-menu.multi-level-select ul {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.dropdown-menu ul li {
  display: block;
  position: relative;
  /*float: left;*/
  min-width: 200px;
  line-height: 35px;
  text-decoration: none;
  background: #fff;
  padding: 4px 20px;
  border-bottom: 2px solid #f6f6f6;
  max-height: 250px; }

.recom-name {
  font-size: 13px; }

.recom-content {
  margin-bottom: 2%; }

.recom-border {
  content: "";
  display: block;
  margin-bottom: 3%;
  border-bottom: 1px dashed #d91980; }

.dropdown-menu li li a {
  font-size: 12px; }

.dropdown-menu li:hover {
  background: #d4d4d4;
  text-align: left; }

/*--- Sublist Styles ---*/
.dropdown-menu ul {
  position: absolute;
  display: none;
  max-height: 200px; }

/*--- Hide Sub Sublists ---*/
.dropdown-menu li:hover ul ul {
  display: none; }

/*--- Sublevel UL's display and position on hover ---*/
#hover-picklist .dropdown-menu li:hover ul {
  /*display: block;*/
  max-height: 40rem;
  width: 97%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  z-index: 1;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.99);
  position: absolute;
  max-height: 250px; }

.dropdown-menu li:hover ul {
  display: block; }

.dropdown-menu li li:hover ul {
  margin-left: 0;
  margin-top: 0;
  display: block;
  right: -200px;
  z-index: 990;
  top: 0; }

nu-li {
  padding: 10px; }

.dropdown-submenu {
  position: absolute;
  left: 0px;
  top: 0px; }
