#logged-out-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px; }

#logged-out-header__left, #logged-out-header__right {
  width: 33%; }

#logged-out-header__middle .logo-pointer {
  cursor: pointer; }

#logged-out-header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  @media (max-width: 768.98px) {
    #logged-out-header__right .sign-in-sign-up-divider {
      display: none; } }
  @media (max-width: 768.98px) {
    #logged-out-header__right {
      flex-direction: column;
      align-items: flex-end; } }
  #logged-out-header__right .icon {
    margin-right: 33px; }
    #logged-out-header__right .icon:last-child {
      margin-right: 0; }

#logged-out-header__donor-link {
  display: none;
  visibility: hidden; }
  @media (min-width: 769px) {
    #logged-out-header__donor-link {
      display: inline;
      visibility: visible; } }

.logged-out-header__donor-cta {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 20px 24px;
  margin-right: 25px; }
