.cta-button {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  transition: transform 0.25s; }
  .cta-button:disabled {
    background-color: #737373 !important;
    border: 1px solid #737373 !important; }
  .cta-button.reverse {
    flex-direction: row-reverse; }
    .cta-button.reverse .cta-button__icon {
      margin-left: 8px; }
    .cta-button.reverse .cta-button__arrow {
      margin-right: 12px;
      transform: rotate(180deg); }
    .cta-button.reverse.with-icon {
      padding: 12px 16px 12px 24px; }
    .cta-button.reverse:hover .cta-button__arrow {
      transform: rotate(180deg) translateX(8px); }
  .cta-button:hover .cta-button__arrow {
    transform: translateX(8px); }
  .cta-button.primary {
    color: #ffffff;
    background-color: #1C21DF;
    border: 1px solid #1C21DF;
    letter-spacing: normal; }
    .cta-button.primary.outline, .cta-button.primary.with-arrow {
      color: #1a282c; }
    .cta-button.primary:hover {
      transform: scale(1.04); }
  .cta-button.secondary {
    color: #1a282c;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6; }
    .cta-button.secondary.outline, .cta-button.secondary.with-arrow {
      color: #ffffff; }
    .cta-button.secondary:hover {
      transform: scale(1.04); }
  .cta-button.success {
    color: #ffffff;
    background-color: #1E5558;
    border: 1px solid #1E5558; }
    .cta-button.success.outline, .cta-button.success.with-arrow {
      color: #ffffff; }
    .cta-button.success:hover {
      transform: scale(1.04); }
  .cta-button.tertiary {
    color: #ffffff;
    background-color: #1C21DF;
    border: none;
    letter-spacing: normal; }
    .cta-button.tertiary:hover {
      transform: scale(1.04); }
  .cta-button.inverse {
    color: #1a282c;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    letter-spacing: normal; }
  .cta-button.with-icon {
    padding: 12px 24px 12px 16px; }
  .cta-button.outline {
    background-color: transparent; }
  .cta-button.small {
    padding: 7px 16px; }
    .cta-button.small:hover {
      transform: scale(1.08); }
  .cta-button.dark {
    color: #1a282c; }
  .cta-button.with-arrow {
    border: none; }

.cta-button__icon {
  margin-right: 8px; }

.cta-button__arrow {
  margin-left: 12px;
  transition: transform 0.25s;
  margin-top: 2px; }

.tertiary.with-icon.with-arrow .cta-button__icon {
  transition: transform 0.25s; }

.tertiary.with-icon.with-arrow .cta-button__text {
  transition: transform 0.25s; }

.tertiary.with-icon.with-arrow:hover .cta-button__icon {
  transform: scale(0); }

.tertiary.with-icon.with-arrow:hover .cta-button__text {
  transform: translateX(-45px); }

.tertiary.with-icon.with-arrow:hover .cta-button__arrow {
  transform: none; }
