@-moz-keyframes "dot" {
  50% {
    transform: translateX(126px); } }

@-webkit-keyframes "dot" {
  50% {
    transform: translateX(126px); } }

@-o-keyframes "dot" {
  50% {
    transform: translateX(126px); } }

@keyframes "dot" {
  50% {
    transform: translateX(126px); } }

@-moz-keyframes "dots" {
  50% {
    transform: translateX(-31px); } }

@-webkit-keyframes "dots" {
  50% {
    transform: translateX(-31px); } }

@-o-keyframes "dots" {
  50% {
    transform: translateX(-31px); } }

@keyframes "dots" {
  50% {
    transform: translateX(-31px); } }

body {
  margin: 0;
  padding: 0; }

.gooey {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background: rgba(255, 255, 255, 0.7);
  filter: contrast(20);
  display: flex;
  z-index: 999999;
  align-items: center;
  justify-content: center; }
  .gooey .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: -30px;
    filter: blur(4px);
    background: #1E5558;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite; }
  .gooey .dots {
    transform: translateX(0);
    margin: 0 0 0 0;
    animation: dots 2.8s infinite; }
    .gooey .dots span {
      display: block;
      float: left;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      filter: blur(4px);
      background: #1C21DF;
      border-radius: 50%; }
