#logged-out-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px; }

#logged-out-header__left, #logged-out-header__right {
  width: 33%; }
  @media (max-width: 479.98px) {
    #logged-out-header__left, #logged-out-header__right {
      margin-left: 10px; } }
  @media (max-width: 1023.98px) {
    #logged-out-header__left .do-not-have-an-account, #logged-out-header__right .do-not-have-an-account {
      max-width: 80px; } }

#logged-out-header__middle .logo-pointer {
  cursor: pointer; }
  @media (max-width: 479.98px) {
    #logged-out-header__middle .logo-pointer #logged-out-header__brand-mark {
      width: 150px; } }

#logged-out-header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right; }
  @media (max-width: 768.98px) {
    #logged-out-header__right .CTABlack {
      margin-left: 0px;
      text-align: center; } }
  #logged-out-header__right .icon {
    margin-right: 33px; }
    #logged-out-header__right .icon:last-child {
      margin-right: 0; }

#logged-out-header__donor-link {
  display: none;
  visibility: hidden; }
  @media (min-width: 769px) {
    #logged-out-header__donor-link {
      display: inline;
      visibility: visible; } }

.logged-out-header__donor-cta {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 20px 24px;
  margin-right: 25px; }

.logged-out-header__sign-in-cta.sign-in-text {
  padding: 3px 15px 3px 15px;
  border-radius: 6px;
  border: 1px solid #ffffff;
  color: #ffffff !important;
  margin-right: 10px; }
  @media (max-width: 768.98px) {
    .logged-out-header__sign-in-cta.sign-in-text {
      margin-right: 0;
      margin-bottom: 5px;
      margin-left: 5px;
      padding: 0px 9px 0px 9px; } }

.logged-out-header__sign-in-cta.sign-up-text {
  margin-left: 5px; }
  @media (max-width: 768.98px) {
    .logged-out-header__sign-in-cta.sign-up-text {
      margin-left: 0; } }

#marketplace-icon {
  border: 'none';
  font-size: 14px;
  padding: 0px 15px 0px 15px; }
  #marketplace-icon img {
    border: 'none';
    width: '25px';
    height: '25px';
    margin-right: 5px; }
