.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f6f6f6;
  background-color: #ffffff;
  padding: 30px;
  align-items: flex-start;
  visibility: hidden;
  transition: opacity 0.25s;
  z-index: 2; }
  .menu.open {
    opacity: 1;
    visibility: visible; }
  .menu li {
    margin-bottom: 16px;
    color: #1a282c;
    transition: color 0.25s; }
    .menu li:last-child {
      margin-bottom: 0; }
    .menu li:hover {
      color: #1a282c; }
