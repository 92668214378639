#donor-header__inner {
  display: flex;
  align-items: center;
  height: 100%; }

#donor-header__brand-mark {
  margin-left: 40px;
  padding-right: 40px;
  height: 100%;
  min-width: 40px; }
  @media (max-width: 768.98px) {
    #donor-header__brand-mark {
      margin-left: 15px;
      padding-right: 15px; } }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    #donor-header__brand-mark {
      margin-left: 20px;
      padding-right: 20px; } }

#donor-header__left {
  display: flex;
  align-items: center;
  height: 100%; }

#header_left_applicant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100% !important; }

#donor-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 55px; }
  #donor-header__right .help-icon {
    margin-right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer; }
  #donor-header__right .icon {
    margin-right: 20px; }
    #donor-header__right .icon:last-child {
      margin-right: 0; }

#donor-header__notifications-icon {
  position: relative; }
  @media (max-width: 768.98px) {
    #donor-header__notifications-icon {
      display: none; } }

#donor-header__chat-icon {
  position: relative; }
  @media (max-width: 768.98px) {
    #donor-header__chat-icon {
      display: none; } }

.recommender-header .msg-overlay-conversation-bubble.chat-window-1.false {
  right: 310px; }

.recommender-header .open-particular-chatting .chat-window-2 {
  right: 675px; }

.recommender-header .msg-overlay-conversation-bubble.chat-window-1.msg-overlay-conversation-bubble--jumbo {
  right: 310px; }

#donor-header__notifications-arrow {
  transform: rotate(180deg);
  position: absolute;
  left: -2px;
  top: 38px;
  display: none; }
  #donor-header__notifications-arrow.open {
    display: block; }

#donor-header__chat-arrow {
  transform: rotate(180deg);
  position: absolute;
  left: -2px;
  top: 38px;
  display: none; }
  #donor-header__chat-arrow.open {
    display: block; }

#donor-header__notification-menu {
  background-color: #ffffff;
  position: absolute;
  height: calc(100vh - 80px);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  top: 80px;
  padding: 50px 20px 0;
  text-align: left;
  width: 570px;
  right: -100%;
  visibility: hidden;
  z-index: 25;
  overflow-y: auto; }
  #donor-header__notification-menu.open {
    right: 0;
    visibility: visible; }
  #donor-header__notification-menu .notification-list__item .task-list-column-1 {
    width: 100%; }
    #donor-header__notification-menu .notification-list__item .task-list-column-1 .notification-list__item-icon {
      min-width: 32px; }
    #donor-header__notification-menu .notification-list__item .task-list-column-1 .notification-list__item-copy .BodyDefaultBoldBlack {
      max-width: unset;
      white-space: unset; }

#donor-header__chat-menu {
  background-color: #ffffff;
  position: absolute;
  height: calc(100vh - 80px);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  top: 80px;
  text-align: left;
  width: 440px;
  right: -100%;
  visibility: hidden;
  z-index: 25;
  overflow-y: auto; }
  #donor-header__chat-menu.open {
    right: 0;
    visibility: visible; }

#layout-wrapper #header__inner.header.DONOR .header__menu {
  margin-left: 0px; }
  #layout-wrapper #header__inner.header.DONOR .header__menu li {
    margin-right: 0px;
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 13px;
    padding-right: 13px;
    cursor: pointer; }
    #layout-wrapper #header__inner.header.DONOR .header__menu li:hover {
      background: #35888D; }
    #layout-wrapper #header__inner.header.DONOR .header__menu li.active-current-page {
      background: #1E5558;
      font-style: italic; }
      #layout-wrapper #header__inner.header.DONOR .header__menu li.active-current-page.Dashboard {
        background: #1E5558;
        font-style: italic; }

.active-current-page {
  background: #1E5558;
  padding: 28px 20px 28px 20px;
  color: #ffffff;
  font-style: italic; }

.application_login_header_main {
  background-color: #1a282c;
  border-bottom: 1px solid #1a282c; }
  .application_login_header_main #header_left_applicant .header__menu {
    margin-left: 20px; }
    @media screen and (min-width: 600px) and (max-width: 900px) {
      .application_login_header_main #header_left_applicant .header__menu {
        margin-left: auto; } }
    .application_login_header_main #header_left_applicant .header__menu li {
      margin-right: 0px;
      padding-left: 10px;
      padding-right: 10px; }
      @media screen and (min-width: 600px) and (max-width: 900px) {
        .application_login_header_main #header_left_applicant .header__menu li {
          padding-left: 7px;
          padding-right: 7px; } }
  .application_login_header_main #donor-header__right .main-button-div {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .application_login_header_main #donor-header__right .main-button-div .program-sponsors.applicant-accounts-dropdown .field.field-group__field-full {
      margin-bottom: 5px;
      width: 320px !important; }
    .application_login_header_main #donor-header__right .main-button-div .marketplace_button {
      margin-right: 20px;
      margin-left: 20px; }
      .application_login_header_main #donor-header__right .main-button-div .marketplace_button button {
        font-family: 'Inter-Medium';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff !important; }

.CTAWhite_Applicant_header {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  padding: 28px 11px; }
  .CTAWhite_Applicant_header:hover {
    background: #35888D; }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    .CTAWhite_Applicant_header {
      font-size: 13px; } }
