#account-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999991; }

#account-loading-screen__logo {
  max-width: 100px; }
  #account-loading-screen__logo img {
    width: 100%; }
