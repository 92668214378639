#reviewers-screen {
  height: 100vh;
  margin-left: 238px;
  padding-left: 15px;
  padding-right: 10px;
  overflow: hidden;
  background-color: #ffffff;
  max-width: calc(100vw - 256px) !important;
  position: relative;
  top: 82px; }
  #reviewers-screen #reviewers-screen__inner {
    border: 1px solid gray;
    border-radius: 10px 10px 0px 0px;
    background-color: #F7F7F2;
    padding: 25px; }

#reviewers-screen__inner {
  padding: 30px 50px 75px;
  margin: 0 auto; }

#reviewers-screen__back-button {
  margin-bottom: 62px;
  margin-left: -35px; }

#reviewers-screen__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px; }

#reviewers-screen__reviewer-table.table__container {
  background-color: #1C21DF; }

#reviewers-screen__reviewer-table .dot-menu svg g {
  fill: #ffffff; }

#reviewers-screen__reviewer-table table {
  background-color: white;
  margin-bottom: 100px; }
  #reviewers-screen__reviewer-table table tr,
  #reviewers-screen__reviewer-table table th {
    color: #f6f6f6; }
  #reviewers-screen__reviewer-table table tbody tr {
    border-bottom: 1px solid rgba(246, 246, 246, 0.3); }
    #reviewers-screen__reviewer-table table tbody tr:hover {
      background-color: rgba(246, 246, 246, 0.3); }

.reviewers-screen-client {
  background: #fff !important; }
  .reviewers-screen-client #reviewers-screen__reviewer-table.table__container table tbody tr {
    border-bottom: 1px solid #eeeeee; }
  .reviewers-screen-client .H1DesktopWhite {
    color: #008067; }
  .reviewers-screen-client #reviewers-screen__top button {
    background-color: #1A282B;
    border-color: #1A282B; }
  .reviewers-screen-client .table__column-header {
    color: #1a282c; }
  .reviewers-screen-client .date-cell-type {
    color: #1A282B; }
  .reviewers-screen-client #reviewers-screen__inner {
    background-color: white; }
  .reviewers-screen-client .table__container {
    background-color: white !important; }
  .reviewers-screen-client #reviewers-screen__back-button .cta-button__arrow path {
    fill: #1a282c; }
