.progress-complete-cell-type {
  display: flex;
  align-items: center;
  width: 100%; }

.sponsor-tag-container > .date-cell-type {
  display: unset !important; }

.progress-complete-cell-type__text {
  margin-right: 10px;
  height: 15px; }

.progress-complete-cell-type__bar-container {
  position: relative;
  width: 100%;
  max-width: 180px; }

.progress-complete-cell-type__bar {
  position: absolute;
  z-index: 1;
  height: 10px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 4px; }
  .progress-complete-cell-type__bar.active {
    z-index: 2;
    background-color: #1C21DF; }
