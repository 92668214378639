.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

#account-switcher {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: #F7F7F3; }

#account-switcher__logo {
  margin: 20px 0 0 40px; }
  @media (max-width: 768.98px) {
    #account-switcher__logo {
      margin: 20px 0 20px 0px !important;
      display: flex;
      align-items: center;
      justify-content: center; } }

#account-switcher__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);
  margin-top: -5%; }

#account-switcher__title {
  margin-bottom: 28px;
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

#account-switcher__copy {
  margin-bottom: 24px;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }
  #account-switcher__copy .CTAGreen {
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #008067; }
  @media (max-width: 768.98px) {
    #account-switcher__copy {
      margin: 20px;
      text-align: center; } }

#account-switcher__accounts {
  display: flex;
  flex-direction: column; }
  #account-switcher__accounts li {
    margin-bottom: 16px; }
    #account-switcher__accounts li:last-child {
      margin-bottom: 0; }

.account-switcher__account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f0f2f2;
  width: 428px;
  background: #ffffff; }
  .account-switcher__account:hover path {
    fill: #1C21DF; }

.account-switcher__badge {
  padding: 25px 0 25px 25px;
  margin-right: 13px; }

.account-switcher__left {
  display: flex;
  align-items: center; }
  .account-switcher__left .account-switcher__company {
    padding: 25px; }

.account-switcher__arrow {
  margin: 0 25px;
  min-width: 24px; }
  .account-switcher__arrow path {
    fill: #ffffff; }
